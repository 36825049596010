<template>
	<v-card class="mb-4 ma-3" >
		<div class="d-flex flex-no-wrap justify-space-between pr-3" :style="'border-radius:4px;border-right:2px solid ' + getBorderColorTag(post)">
		<v-container >
			<v-layout row wrap>
			<v-flex xs12 sm4>
				<v-img style="border-radius:4px 0px 0px 4px" min-height="250" :src="getImageUrl(post)">
				<template v-slot:placeholder>
					<v-row class="fill-height ma-0" style="background-color:#EAEAEA" align="center" justify="center">
					<v-progress-circular indeterminate color="red lighten-2"></v-progress-circular>
					</v-row>
				</template>
				</v-img>
			</v-flex>
			<v-flex xs12 sm8 class="pl-2" >
				<v-flex style="flex-direction:column!important">
				<v-flex style="min-height:200px">
					<div v-if="post.seccion!=null" class="post-seccion">{{post.seccion}}</div>
					<div class="text-h6 post-titulo" :style="getEstiloTitulo(post)">{{post.titulo}}</div>
					<div class="text-subtitle-2">{{getFecha(post)}}</div>
					<div class="block-with-text ma-1" v-html="post.texto"></div>
				</v-flex>
				<v-card-actions class="pl-0 pb-2 justify-space-between" style="padding:0px;margin-top: 10px;">
					<v-btn class="v-btn-grad" v-if="post.tipo=='CAPITULO'" color="primary" dark :href="'#/capitulo/' + post.id">Leer Más</v-btn>
					<v-btn class="v-btn-grad" v-if="post.tipo=='ILUSTRE'" color="primary" dark :href="'#/ilustre/' + post.id">Leer Más</v-btn>
					<v-btn class="v-btn-grad" v-if="post.tipo=='TEXTO'" color="primary" dark :href="'#/texto/' + post.id">Leer Más</v-btn>
					<v-btn class="v-btn-grad" v-if="post.tipo=='ENTREVISTA'" color="primary" dark :href="'#/bso-escucha/1'">Ver entrevistas</v-btn>
					<v-btn v-if="$root.$isAdmin" fab color="green" dark small  @click="copiarStaticLink(post)"> 
                        <v-icon color="white">mdi-clipboard-text</v-icon>
                    </v-btn>
				</v-card-actions>
				</v-flex>
			</v-flex>
			</v-layout>
		</v-container>
		</div>
		<v-chip class="v-btn--small tag-item" style="border-radius: 0px 4px 0px 5px!important;" label :color="getColorTag(post)" text-color="white">
		<v-icon left>{{getIconoTag(post)}}</v-icon>{{getTextoTag(post)}}
		</v-chip>
	</v-card>
</template>

<script>
import dayjs from 'dayjs'

export default {
	data () {
		return {}
	},
	props: ['post'],
	methods:{
		getImageUrl: function(post){
			return this.$root.$basepath + 'api/thumbnail.php?ty=ca&i=' + encodeURIComponent(post.imagen) + this.getWebp();
		},
		getWebp: function(){
			return (this.$modernizr.webp?"&webp=1":"");
		},
		getFecha: function(post) {
			return dayjs(post.fecha).format('DD/MM/YYYY');
		},
		getColorTag:function(post){
			if (post.tag==null){return;}
			if (this.esNuevo(post)){
				return 'red';
			}
			return post.tag.color;
		},
		getTextoTag:function(post){
			if (post.tag==null){return;}
			let texto="";
			if (this.esNuevo(post)){
				texto = "¡NUEVO! ";
			}
			texto+=post.tag.texto;
			return texto;
		},
		getIconoTag:function(post){
			if (post.tag==null){return;}
			return post.tag.icono;
		},
		getBorderColorTag:function(post){
			if (post.tag==null){return;}
			if (this.esNuevo(post)){
				return '#F44336';
			}
			return post.tag.borderColor;
		},
		esNuevo: function(post){
			if (post==null){return;}
			let fechaLim=dayjs().subtract(14, 'day');
			let fechaPost=dayjs(post.fecha);
			return fechaPost.isAfter(fechaLim);
		},
		copiarStaticLink:function(post){
			let url='https://www.bsoradio.com.ar/static/';
			url+=post.tipo.toLowerCase() + '_' + post.id + ".html";

			/* Get the text field */
			var copyText = document.getElementById("clip");
			copyText.value=url;

			/* Select the text field */
			copyText.style.display="block";
			copyText.select();

			/* Copy the text inside the text field */
			document.execCommand("copy");
			copyText.style.display="none";
		},
		getEstiloTitulo:function(post){
			if (this.$vuetify.breakpoint.smAndUp){
				if (post!=null && this.esNuevo(post)){
					return 'margin-right:180px;';
				}
				else{
					return 'margin-right:150px;';
				}
			}
			return '';
		}
	}
};
</script>
<style>
.post-seccion {
  font-size: 16px !important;
  /*color: red;*/
  color: black; 
}

.post-titulo {
 /* color: red;*/
 color: black; 
 line-height: 1.5rem;
}

.post-texto {
  max-height: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: justify;
}

.tag-item {
  position:absolute!important;
  right:0px!important;
  top:0px!important;
}

/* styles for '...' */ 
.block-with-text {
  /* hide text if it more than N lines  */
  overflow: hidden;
  /* for set '...' in absolute position */
  position: relative; 
  /* use this value to count block height */
  line-height: 1.2em;
  /* max-height = line-height (1.2) * lines max number (3) */
  max-height: 8.4em; 
  /* fix problem when last visible word doesn't adjoin right side  */
  text-align: justify;  
  /* place for '...' */
  margin-right: -1em;
  padding-right: 1em;
}
/* create the ... */
.block-with-text:before {
  /* points in the end */
  content: '...';
  /* absolute position */
  position: absolute;
  /* set position to right bottom corner of block */
  right: 0;
  bottom: 0;
}
/* hide ... if we have text, which is less than or equal to max lines */
.block-with-text:after {
  /* points in the end */
  content: '';
  /* absolute position */
  position: absolute;
  /* set position to right bottom corner of text */
  right: 0;
  /* set width and height */
  width: 1em;
  height: 1em;
  margin-top: 0.2em;
  /* bg color = bg color under block */
  background: white;
}
</style>